import axios from "axios"
import { apiURLV2 } from "./utils"

export const setAuthToken = token => {
  axios.defaults.headers.common.Authorization = ``
  if (token) axios.defaults.headers.common.Authorization = `Token ${token}`
}

export const login = data => axios.post(`${apiURLV2}/dj-rest-auth/login/`, data)

export const me = data => axios.get(`${apiURLV2}/me/`, data)

export const logout = () => axios.post(`${apiURLV2}/dj-rest-auth/logout/`)

export const updateProfile = (data, contentType) =>
  axios.patch(`${apiURLV2}/me/`, data, {
    headers: {
      "content-type": contentType || "application/json",
    },
  })

export const register = data =>
  axios.post(`${apiURLV2}/dj-rest-auth/registration/`, data)

export const loginGoogle = data =>
  axios.post(`${apiURLV2}/dj-rest-auth/google/`, data)
