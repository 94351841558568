import { navigate } from "gatsby"

export const apiURL = process.env.GATSBY_API_URL
  ? process.env.GATSBY_API_URL
  : "https://api.v1.purwalenta.com/"

export const apiURLV2 = process.env.GATSBY_API_URL_V2
  ? process.env.GATSBY_API_URL_V2
  : "https://api.v4.purwalenta.com"

export const myToken = "536e1137173bdf2a1452d014c0b3055e"

// STAGING
// export const googleClientId = "362236836392-o5ne75msuchjgcme10d9h5lfs86qttsp.apps.googleusercontent.com"

// PRODUCTION
export const googleClientId =
  "362236836392-9m1f9t4qsichu117nlga16go3pu1i7id.apps.googleusercontent.com"

export const isBrowser = () => typeof window !== "undefined"

export const getParameterByName = name => {
  const url = isBrowser() && window.location.href
  name = name.replace(/[[\]]/g, "\\$&")
  const regex = new RegExp("[?&]" + name + "(=([^&#]*)|&|#|$)")
  const results = regex.exec(url)
  if (!results) return null
  if (!results[2]) return ""
  return decodeURIComponent(results[2].replace(/\+/g, " "))
}

export const redirectTo = url => {
  isBrowser() && window.location.replace(url)
}

export const redirectWithHistory = url => {
  isBrowser() && (window.location.href = url)
}

export const openInNewTab = url => {
  isBrowser() && window.open(url)
}

export const getQueryVariable = variable => {
  if (isBrowser()) {
    const query = window.location.search.substring(1)
    const vars = query.split("&")
    for (let i = 0; i < vars.length; i++) {
      const pair = vars[i].split("=")
      if (pair[0] === variable) {
        return pair[1]
      }
    }
  }
  return false
}

export const updateQueryParams = ({ key, value }) => {
  if (isBrowser()) {
    if ("URLSearchParams" in window) {
      var searchParams = new URLSearchParams(window.location.search)
      searchParams.set(key, value)

      var newRelativePath = `${
        window.location.pathname
      }?${searchParams.toString()}`
      navigate(newRelativePath)
    }
  }
  return false
}

export const formatUang = nominal => {
  if (!nominal) return "0"
  nominal = Number(nominal)
  let result = ""
  let counter = 0
  while (nominal > 0) {
    if (counter > 0 && counter % 3 == 0) {
      result = "." + result
    }
    result = String(nominal % 10) + result
    nominal = Math.floor(nominal / 10)
    counter += 1
  }
  return result
}

export const toBase64 = file =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = error => reject(error)
  })

export function checkImage(imageSrc, good, bad) {
  var img = new Image()
  img.onload = good
  img.onerror = bad
  img.src = imageSrc
}
