import { createSlice } from "@reduxjs/toolkit"

const defaultUser = {
  id: "-1",
  role_id: "",
  email: "",
  display_name: "",
  username: "",
  trimmedFullname: "",
  user_type: "",
  phone: "",
  dob: "",
  profile_picture: "",
  personality: "",
  bank: "",
  acc_bank: "",
  skill: "",
  profesi: "",
  instansi: "",
  title: "",
  bio: "",
  is_tutor: "",
}

const trimFullname = user => {
  if (user.display_name?.length > 16) {
    return `${user.display_name.slice(0, 14)}..`
  }
  return user.display_name
}

const session = createSlice({
  name: "session",
  initialState: {
    isAuthenticated: false,
    token: "",
    user: JSON.parse(JSON.stringify(defaultUser)),
  },
  reducers: {
    loginSuccess: (state, action) => {
      state.isAuthenticated = true
      state.token = action.payload.key
      state.user = {
        ...action.payload,
        trimmedFullname: trimFullname(action.payload),
      }
    },
    logoutSuccess: (state, action) => {
      state.isAuthenticated = false
      state.token = ""
      state.user = {}
    },
    logoutFailed: () => {},
    updateAccount: (state, action) => {
      state.user = {
        ...action.payload,
        trimmedFullname: trimFullname(action.payload),
      }
    },
    resetAccount: state => {
      state.user = JSON.parse(JSON.stringify(defaultUser))
      state.isAuthenticated = false
      state.token = ""
    },
  },
})

export const {
  loginSuccess,
  loginFailed,
  logoutSuccess,
  logoutFailed,
  updateAccount,
  resetAccount,
} = session.actions

export default session.reducer
