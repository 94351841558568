exports.components = {
  "component---src-pages-404-jsx": () => import("./../../../src/pages/404.jsx" /* webpackChunkName: "component---src-pages-404-jsx" */),
  "component---src-pages-500-jsx": () => import("./../../../src/pages/500.jsx" /* webpackChunkName: "component---src-pages-500-jsx" */),
  "component---src-pages-[course]-notes-course-[note]-jsx": () => import("./../../../src/pages/course/notes/[course]/[note].jsx" /* webpackChunkName: "component---src-pages-[course]-notes-course-[note]-jsx" */),
  "component---src-pages-certificate-[id]-jsx": () => import("./../../../src/pages/certificate/[id].jsx" /* webpackChunkName: "component---src-pages-certificate-[id]-jsx" */),
  "component---src-pages-certificate-request-course-id-jsx": () => import("./../../../src/pages/certificate/request/[course_id].jsx" /* webpackChunkName: "component---src-pages-certificate-request-course-id-jsx" */),
  "component---src-pages-course-jsx": () => import("./../../../src/pages/course.jsx" /* webpackChunkName: "component---src-pages-course-jsx" */),
  "component---src-pages-course-video-jsx": () => import("./../../../src/pages/course-video.jsx" /* webpackChunkName: "component---src-pages-course-video-jsx" */),
  "component---src-pages-dashboard-courses-jsx": () => import("./../../../src/pages/dashboard/courses.jsx" /* webpackChunkName: "component---src-pages-dashboard-courses-jsx" */),
  "component---src-pages-dashboard-drafts-jsx": () => import("./../../../src/pages/dashboard/drafts.jsx" /* webpackChunkName: "component---src-pages-dashboard-drafts-jsx" */),
  "component---src-pages-dashboard-events-jsx": () => import("./../../../src/pages/dashboard/events.jsx" /* webpackChunkName: "component---src-pages-dashboard-events-jsx" */),
  "component---src-pages-dashboard-index-jsx": () => import("./../../../src/pages/dashboard/index.jsx" /* webpackChunkName: "component---src-pages-dashboard-index-jsx" */),
  "component---src-pages-dashboard-tutor-audience-detail-jsx": () => import("./../../../src/pages/dashboard/tutor/audience/detail.jsx" /* webpackChunkName: "component---src-pages-dashboard-tutor-audience-detail-jsx" */),
  "component---src-pages-dashboard-tutor-audience-index-jsx": () => import("./../../../src/pages/dashboard/tutor/audience/index.jsx" /* webpackChunkName: "component---src-pages-dashboard-tutor-audience-index-jsx" */),
  "component---src-pages-dashboard-tutor-balance-jsx": () => import("./../../../src/pages/dashboard/tutor/balance.jsx" /* webpackChunkName: "component---src-pages-dashboard-tutor-balance-jsx" */),
  "component---src-pages-dashboard-tutor-course-jsx": () => import("./../../../src/pages/dashboard/tutor/course.jsx" /* webpackChunkName: "component---src-pages-dashboard-tutor-course-jsx" */),
  "component---src-pages-dashboard-tutor-index-jsx": () => import("./../../../src/pages/dashboard/tutor/index.jsx" /* webpackChunkName: "component---src-pages-dashboard-tutor-index-jsx" */),
  "component---src-pages-events-create-your-creativity-jsx": () => import("./../../../src/pages/events/create-your-creativity.jsx" /* webpackChunkName: "component---src-pages-events-create-your-creativity-jsx" */),
  "component---src-pages-events-meaningful-language-learning-jsx": () => import("./../../../src/pages/events/meaningful-language-learning.jsx" /* webpackChunkName: "component---src-pages-events-meaningful-language-learning-jsx" */),
  "component---src-pages-events-the-future-of-work-jsx": () => import("./../../../src/pages/events/the-future-of-work.jsx" /* webpackChunkName: "component---src-pages-events-the-future-of-work-jsx" */),
  "component---src-pages-forget-password-index-jsx": () => import("./../../../src/pages/forget-password/index.jsx" /* webpackChunkName: "component---src-pages-forget-password-index-jsx" */),
  "component---src-pages-forget-password-reset-jsx": () => import("./../../../src/pages/forget-password/reset.jsx" /* webpackChunkName: "component---src-pages-forget-password-reset-jsx" */),
  "component---src-pages-hubungi-jsx": () => import("./../../../src/pages/hubungi.jsx" /* webpackChunkName: "component---src-pages-hubungi-jsx" */),
  "component---src-pages-index-jsx": () => import("./../../../src/pages/index.jsx" /* webpackChunkName: "component---src-pages-index-jsx" */),
  "component---src-pages-login-jsx": () => import("./../../../src/pages/login.jsx" /* webpackChunkName: "component---src-pages-login-jsx" */),
  "component---src-pages-maintenance-index-jsx": () => import("./../../../src/pages/maintenance/index.jsx" /* webpackChunkName: "component---src-pages-maintenance-index-jsx" */),
  "component---src-pages-menjadi-tutor-jsx": () => import("./../../../src/pages/menjadi-tutor.jsx" /* webpackChunkName: "component---src-pages-menjadi-tutor-jsx" */),
  "component---src-pages-mobile-privacy-policy-jsx": () => import("./../../../src/pages/mobile/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-mobile-privacy-policy-jsx" */),
  "component---src-pages-mobile-tentang-kami-jsx": () => import("./../../../src/pages/mobile/tentang-kami.jsx" /* webpackChunkName: "component---src-pages-mobile-tentang-kami-jsx" */),
  "component---src-pages-mobile-terms-and-conditions-jsx": () => import("./../../../src/pages/mobile/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-mobile-terms-and-conditions-jsx" */),
  "component---src-pages-payment-error-jsx": () => import("./../../../src/pages/payment/error.jsx" /* webpackChunkName: "component---src-pages-payment-error-jsx" */),
  "component---src-pages-payment-finish-jsx": () => import("./../../../src/pages/payment/finish.jsx" /* webpackChunkName: "component---src-pages-payment-finish-jsx" */),
  "component---src-pages-payment-unfinish-jsx": () => import("./../../../src/pages/payment/unfinish.jsx" /* webpackChunkName: "component---src-pages-payment-unfinish-jsx" */),
  "component---src-pages-personality-test-index-jsx": () => import("./../../../src/pages/personality-test/index.jsx" /* webpackChunkName: "component---src-pages-personality-test-index-jsx" */),
  "component---src-pages-personality-test-ongoing-test-[id]-jsx": () => import("./../../../src/pages/personality-test/ongoing-test/[id].jsx" /* webpackChunkName: "component---src-pages-personality-test-ongoing-test-[id]-jsx" */),
  "component---src-pages-personality-test-test-result-[id]-jsx": () => import("./../../../src/pages/personality-test/test-result/[id].jsx" /* webpackChunkName: "component---src-pages-personality-test-test-result-[id]-jsx" */),
  "component---src-pages-privacy-policy-jsx": () => import("./../../../src/pages/privacy-policy.jsx" /* webpackChunkName: "component---src-pages-privacy-policy-jsx" */),
  "component---src-pages-quiz-jsx": () => import("./../../../src/pages/quiz.jsx" /* webpackChunkName: "component---src-pages-quiz-jsx" */),
  "component---src-pages-register-jsx": () => import("./../../../src/pages/register.jsx" /* webpackChunkName: "component---src-pages-register-jsx" */),
  "component---src-pages-search-jsx": () => import("./../../../src/pages/search.jsx" /* webpackChunkName: "component---src-pages-search-jsx" */),
  "component---src-pages-support-[slug]-jsx": () => import("./../../../src/pages/support/[slug].jsx" /* webpackChunkName: "component---src-pages-support-[slug]-jsx" */),
  "component---src-pages-tentang-kami-jsx": () => import("./../../../src/pages/tentang-kami.jsx" /* webpackChunkName: "component---src-pages-tentang-kami-jsx" */),
  "component---src-pages-terms-and-conditions-jsx": () => import("./../../../src/pages/terms-and-conditions.jsx" /* webpackChunkName: "component---src-pages-terms-and-conditions-jsx" */),
  "component---src-pages-tutor-jsx": () => import("./../../../src/pages/tutor.jsx" /* webpackChunkName: "component---src-pages-tutor-jsx" */),
  "component---src-templates-kursus-jsx": () => import("./../../../src/templates/kursus.jsx" /* webpackChunkName: "component---src-templates-kursus-jsx" */),
  "component---src-templates-tutor-jsx": () => import("./../../../src/templates/tutor.jsx" /* webpackChunkName: "component---src-templates-tutor-jsx" */)
}

